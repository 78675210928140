.map-pointer{
    color: red !important;
    margin-top: 70px;
   
}



.teste-map{
   width: 500px;
   
   height: 80vh;
}



@media screen and (max-width: 850px){

  .teste-map{
    width: 400px;
     height: 50vh;
  }
    
}


@media screen and (max-width: 768px) {
    .teste-map{
       width: 500px;
        height: 50vh;
     }
     #map-container {
       width: 100%;
        height: 20vh !important; /* ou qualquer outra altura desejada */
      }
  }



@media screen and (max-width: 538px) {
    .teste-map{
       width: 350px;
        height: 30vh;
     }
     #map-container {
       width: 100%;
        height: 20vh !important; /* ou qualquer outra altura desejada */
      }
  }
  
  
  