.cards-valores {

    background-image: linear-gradient(180deg, #fb2f3f 0%, #7e000b 100%);
    width: 100%;
   height: 100%;
    text-align: center;
    padding-top: 60px;
    padding-bottom: 100px;
    
  
    /* background-image: linear-gradient(90deg, var(--e-global-color-5a96085 ) 0%, #4E0079 100%); */

}
.container-cards{
   
   width: 100%;
}
.flex-box{
   
    display: flex;
   justify-content: center;
    flex-wrap: wrap;
    gap: 60px 30px;
    
   

}
.wrapper-title-cards{
    display: flex;
    flex-direction: column;
    
}

.title-cards .wrapper-title-cards h1 {
   
    font-weight: bold;
    font-family: "Roboto", Montserrat;
    color: #0000;
    
    margin-bottom: -15px;
    

    -webkit-text-stroke-width: 3px;
    -webkit-text-stroke-color: #0c2633;
    font-family: Helvetica;
    color: white;
    font-size:90px;
    
}

.title-cards .wrapper-title-cards span{
   
    font-size: 40px;
    color: #041e2b   ;
    font-family: "Roboto", Montserrat;
    font-weight: 600;
    margin-bottom: 70px;
    padding-left: 190px;
 
  

    
}

.boxes {

    border: none;
    
    border-radius: 25px;
    background-image: linear-gradient(to top, rgb(220, 220, 220) 5%, #eae9e9 90%);
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
    /* margin-top: 70px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding-top: 20px;
   background-color: #0c2633;
   width: auto;
    border: 1px solid white;
    border-radius: 10%; */
   
   
   
   flex-basis: calc(27% - 30px);
  
  

}

.teste {

    background-image: linear-gradient(180deg, #12384b 50%, #041e2b 100%);
    padding: 20px;
    border-radius: 20px 20px 0 0;
}


.border-botton-titles-box{
    border: 1px solid white;
}



.boxes-precos {
    display: flex;
    justify-content: center;
    padding: 15px 0;

}

.preco {
    display: flex;
    flex-direction: column;
    gap: 6px 0;

}

.wrapper-preco {
    display: flex;
    justify-content: center;
    gap: 3px;
    align-items: center;
    padding-bottom: 5px;

}


.wrapper-preco p {
    color: #414141;
    font-weight: bold;
    font-size: 50px;
    font-family: Helvetica;
}

.wrapper-preco span {
    font-weight: bold;
    color: #414141;
    font-size: 15px;
}

.valores {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

}


.descricao-preco {
    
    display: flex;
    flex-direction: column;
    gap: 15px 0;

}



.descricao-preco span {

    font-size: 15px;
    font-weight: 500;
    color: #414141;
    font-family: Verdana;

}

.wrapper-descricao {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 0 5px ;

}

.boxes-itens{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0 8px;
   
}

.button-card {
    display: flex;
    justify-content: center;
    position: relative;
    top: 15px;
    transition: ease-in-out .4s;
}

.button-card button {
    border: none;
    font-size: 15px;
    background-image: linear-gradient(180deg, #12384b 50%, #041e2b 100%);
    color: white;
   
    padding: 10px 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    gap: 5px;
    text-align: center;
}


.icon-buton-card {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
}

.button-card:hover {
    transform: scale(1.1);
}

.titile-boxes :first-child {
    font-size: 50px;
    color: white;
}

.titile-boxes :last-child {
    font-size: 28px;
    color: white;
    text-align: left;
}



.teste .border-botton {
  
   
    border: 1px solid white;
}

.border-botton{
    border: 1px solid gray;
   margin: 18px 0;
   width: 100%;
   
}




@media screen and ( max-width : 1200px){

    .boxes{
        flex-basis: calc(33.3% - 30px);
    }
}


@media screen and ( max-width : 915px){

    .boxes{
        flex-basis: calc(40.3% - 30px);
    }
}
@media screen and ( max-width : 768px){
    
    .boxes{
        flex-basis: 60%;
    }

    .title-cards .wrapper-title-cards h1 {
   
        font-size:60px;
      
    }
    .title-cards .wrapper-title-cards span{
       
       font-size: 32px;
        padding-left: 110px;
    
    }
    


}



  
  @media (max-width: 479px) {
    .boxes {
      flex-basis: 80%;
    }
  }