section.cards {}


/* }.teste-stroke{
    text-align: center;
padding: 100px;

}
.teste-stroke span{
    width: 400px;
    height: 150px;
  -webkit-text-stroke: 1px black;
  color: white;


} */



section.produtos {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 50px;

  background-image: linear-gradient(180deg, rgb(220, 220, 220) 5%, #eae9e9 90%);


}

section.produtos .container-produtos {



  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

}

section.produtos .title-produtos {

  display: flex;
  width: 100%;
  justify-content: flex-start;
  flex-direction: column;
  text-align: center;
}

section.produtos .title-produtos h1 {

  font-size: 90px;
  font-weight: bold;
  

  /* -webkit-text-stroke: 8px #0c2633; */

  /* -webkit-text-stroke: 4px #FFF; */
  /* text-shadow:#000 -1px -1px; */



  text-shadow:
    -1px -1px 0 #ab000e,
    /* sombra superior esquerda */
    1px -1px 0 #ab000e,
    /* sombra superior direita */
    -1px 1px 0 #ab000e,
    /* sombra inferior esquerda */
    1px 1px 0 #ab000e;
  /* sombra inferior direita */

  font-family: Helvetica;
  color: #FFF;

}

section.produtos .title-produtos span {

  margin-bottom: 100px;
  font-size: 50px;
  color: #041e2b;
  font-family: "Roboto", Montserrat;
  font-weight: bold;
  padding-left: 20px;


}

section.produtos .boxes-produtos {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 15px;
  padding-bottom: 30px;


}

section.produtos .boxes-produtos .wrapper-produtos {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border: 1px solid black;
  padding: 30px;
  border-radius: 25px;
  gap: 13px;
  background-image: linear-gradient(to right, #041e2b 5%, #002e44 90%);
  border: none;
  width: 180px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}

section.produtos .boxes-produtos .wrapper-produtos span {
  font-size: 15px;
  font-weight: 500;
  font-family: Helvetica;
  color: white;

}


section.produtos .boxes-produtos .wrapper-produtos:hover {
  transform: scale(1.1);
  transition: ease-in-out 0.4s;

}



section.sua-empresa {
  width: 100%;


}

section.sua-empresa .container-sua-empresa {

  background-image: linear-gradient(to top, transparent -40%, rgb(248, 44, 44) 100%), url("../../images/image-3.jpg");
  opacity: 0.9;
  width: 100%;
  padding: 100px 0;
  background-size: cover;



  /* background-image: linear-gradient(180deg, #fb2f3f 0%, #7e000b 100%); */

  /* #fb2f3f 0%, #7e000b   background-image: linear-gradient(180deg, #fb2f3f 0%, #7e000b 100%); */

}

section.sua-empresa .container-sua-empresa {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  padding-left: 2%;

}

section.sua-empresa .container-sua-empresa .title-sua-empresa {

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;


}

section.sua-empresa .container-sua-empresa .title-sua-empresa span {

  font-size: 60px;
  font-family: Verdana;
  font-weight: bold;
  color: #041e2b;
}

section.sua-empresa .container-sua-empresa .title-sua-empresa h1 {
  margin-top: -30px;
  font-size: 90px;
  font-family: Verdana;
  color: white;
  /* -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: #041e2b; */


  
  text-shadow:
    -1px -1px 0 #041e2b,
    /* sombra superior esquerda */
    1px -1px 0 #041e2b,
    /* sombra superior direita */
    -1px 1px 0 #041e2b,
    /* sombra inferior esquerda */
    1px 1px 0 #041e2b;
  /* sombra inferior direita */


}

section.sua-empresa .container-sua-empresa .title-sua-empresa p {
  font-family: Verdana;
  
  font-size: 20px;
  color: white;
  margin-bottom: 50px;
}


.button-sua-empresa button {

  font-size: 25px;
  background-image: linear-gradient(to right, #003047 5%, #001722 90%);
  border-radius: 50px 50px 50px 50px;
  padding: 15px 35px 15px 35px;
  border: none;
  color: white;
  display: flex;
  gap: 5px;

  transition: ease-in-out 0.4s;
  font-family: 'Roboto';


}

.button-sua-empresa button:hover {
  transform: scale(1.1);
  color: #f82b3c;
  fill: #f82b3c;


}


@media screen and (max-width: 1150px) {
  section.produtos .boxes-produtos .wrapper-produtos {
    flex-basis: 100%;
  }

}

@media screen and (max-width: 768px) {

  section.produtos .boxes-produtos .wrapper-produtos {
    flex-basis: 34%;
  }

  section.produtos .title-produtos {

    width: 100%;
    padding-right: 2%;
  }

  section.produtos .title-produtos h1 {

    font-size: 60px;


  }

  section.produtos .title-produtos span {

    margin-bottom: 100px;
    font-size: 32px;



  }

  .button-sua-empresa button {

    font-size: 15px;
    border-radius: 50px 50px 50px 50px;
    padding: 10px 25px 10px 25px;

  }

  section.sua-empresa .container-sua-empresa {
    height: 350px;
    background-size: 100% 100%;
    background-repeat: no-repeat;
     /* ou scroll */

    /* background-image: linear-gradient(180deg, #fb2f3f 0%, #7e000b 100%); */
    /* #fb2f3f 0%, #7e000b   background-image: linear-gradient(180deg, #fb2f3f 0%, #7e000b 100%); */

  }


  section.sua-empresa .container-sua-empresa .title-sua-empresa {

    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    padding-top: 100px;

  }

  section.sua-empresa .container-sua-empresa .title-sua-empresa span {
    font-size: 37px;
    color: #041e2b;
    margin-bottom: 6px;
  }

  section.sua-empresa 
  .container-sua-empresa 
  .title-sua-empresa h1 {
    margin-top: -30px;
    font-size: 50px;

  }

  section.sua-empresa .container-sua-empresa .title-sua-empresa p {

    font-size: 16px;
    width: 100%;
    text-align: center;
    color: white;
    padding: 0px 2%;
    margin-bottom: 20px;

  }

}

@media screen and (max-width: 468px) {
  section.produtos .boxes-produtos .wrapper-produtos {
    flex-basis: 40%;
  }


  section.produtos .title-produtos {

    width: 100%;
    padding-right: 2%;

  }

  section.produtos .title-produtos h1 {
    font-size: 40px;

  }

  section.produtos .title-produtos span {

    margin-bottom: 55px;
    font-size: 22px;
    padding-left: 22px;



  }

  @media screen and (max-width: 380px) {

    section.produtos .boxes-produtos .wrapper-produtos {
      flex-basis: 20%;
      width: 150px;
    }

    section.produtos .title-produtos h1 {
      font-size: 40px;

    }

    section.produtos .title-produtos span {

      margin-bottom: 55px;
      font-size: 20px;
      padding-left: 12px;



    }

  }






}