.imagem-fundo-paginas {

  width: 100%;

  height: 350px;

  display: flex;
  justify-content: center;
  align-items: center;



  /* background-image: linear-gradient(180deg, #fb2f3f 0%, #7e000b 100%); */

  /* #fb2f3f 0%, #7e000b   background-image: linear-gradient(180deg, #fb2f3f 0%, #7e000b 100%); */

}

.wrapper-imagem-fundo-paginas {
  width: 100%;

}
/* 
.teste-img {
  width: 100%;


  height: 400px;


  background-position: center;
  background-attachment: fixed;
  background-size: cover;
  position: absolute;

  display: flex;
  justify-content: center;
  align-items: center;
} */

.imagem-fundo-paginas img {

  width: 100%;
 
  height: 350px;
  position: absolute;
  object-fit: cover;
  display: block;
  opacity: 1.6;
 
 


}


.imagem-capa-fundo-title {

  position: relative;
  text-align: center;


}


.imagem-capa-fundo-title h1 {

  text-align: center;
  font-size: 55px;
  font-family: Verdana;
  font-weight: 600;
  color: white;
  text-transform: uppercase;
  padding-top: 100px;


  /* 
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: #fb2f3f; */


  text-shadow:
    -1px -1px 0 #fb2f3f,
    /* sombra superior esquerda */
    1px -1px 0 #fb2f3f,
    /* sombra superior direita */
    -1px 1px 0 #fb2f3f,
    /* sombra inferior esquerda */
    1px 1px 0 #fb2f3f;
  /* sombra inferior direita */
}


@media screen and (max-width: 768px) {

  .imagem-fundo-paginas {
    width: 100%;

  }
  .imagem-capa-fundo-title{
    
  }

  .wrapper-imagem-fundo-paginas {
    width: 100%;
   
    

  }

   .imagem-fundo-paginas img {


    object-fit: 100% 100%;
   
    

  } 

  .teste-img {


    /* Use 100% da altura da janela */
 
    background-size: cover;
    background-repeat: no-repeat;
    background-position:top;
    position: absolute;
  }

  .imagem-capa-fundo-title h1 {

    font-size: 40px;
    padding-top: 100px;

  }
}