.wrapper-capa {
    width: 100%;
    height: 600px;
}

.imagem-fundo {

    width: 100%;
    position: absolute;
}
